<template>
    <div>
      <v-dialog v-model="dialog" max-width="600" persistent>
        <v-card>
          <v-card-title>
            Nouveau Modèle Ecommerce
            <v-spacer />
            <v-btn icon class="v-btn--active" color="primary" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-6">
            
            <v-text-field
                label="Désignation *"
                @input="
                    formErrors && formErrors.name
                    ? (formErrors.name = '')
                    : false
                "
                :error-messages="
                    formErrors && formErrors.name ? formErrors.name[0] : ''
                "
                v-model="form.name"
            />
            <div class="d-flex align-center">
                <v-file-input
                  label="Icone *"
                  accept="image/png, image/jpeg, image/jpg, .svg, .gif"
                  show-size
                  suffix="Image"
                  @change="uploadFile"
                  @focus="
                    formErrors && formErrors.image ? (formErrors.image = '') : false
                  "
                  ref="fileImage"
                  chips
                  prepend-icon="mdi-camera"
                  :error-messages="formErrors.image"
                />
                <v-btn
                  depressed
                  icon
                  small
                  class="primary white--text ml-2"
                  v-if="form.image"
                  @click="previewImage(form.image)"
                >
                  <v-icon small>mdi mdi-eye</v-icon>
                </v-btn>
              </div>
              <div class="d-flex align-center justify-space-between">
                <span class="font-weight-bold">
                    Paramètres
                </span>
                <v-btn icon depressed class="primary white--text" @click="addParameter">
                    <v-icon>mdi mdi-plus</v-icon>
                </v-btn>
              </div>

              <v-text-field
                v-for="(value, index) in form.params " :key="index"
                v-model="form.params[index]"
                :label="'('+(index +1)+') Nom' "
              >
              </v-text-field>
  
              <v-btn
                color="primary"
                class="rounded-lg text-none mt-3"
                :loading="btnLoading"
                large
                depressed
                @click="send"
              >
                <v-icon left>mdi-send-outline</v-icon>
                Savegarder
              </v-btn>
              
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
    
    <script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      btnLoading: false,

      formErrors: {},
      form: {
        name: "",
        image: "",
        params: []
      },
      dialog: false
    };
  },

  computed: {},
  methods: {
    addParameter(){
        this.form.params.push("");
    },
    open() {
      if(this.$refs && this.$refs.fileImage){
        this.$refs.fileImage.reset();
      }
      
      this.dialog = true;
    },
    close() {
      //this.$emit("update:dialog", false);
      this.dialog = false;
    },
    send() {
      this.btnLoading = true;

      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });

      HTTP.post("/v1/ecommerce/models", formData, { params: {} })
        .then(() => {
          this.$successMessage = "Enregistrement avec succès";
          this.btnLoading = false;
          this.close();
          this.form = {};
          this.$refs.fileImage.reset();

          this.dialog = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          this.btnLoading = false;
          this.formErrors = err.response.data.errors;
        });
    },

    uploadFile(file) {
      this.form.image = file;
      console.log("icon changed");
    },
  },
  created() {},
};
</script>
    
  <style scoped>
.arabic .v-text-field__slot .v-label {
  text-align: right !important;
  direction: rtl;
  left: inherit;
  right: 0 !important;
}
</style>
    