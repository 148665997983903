<template>
  <div>
    <v-card
      class="rounded-lg shadow"
      :loading="isLoading"
      :disabled="isLoading"
      elevation="0"
    >
      <v-card-text class="p-3">
        <v-skeleton-loader v-if="isLoading" type="table" />

        <div v-else>
          <div class="d-flex justify-space-between mb-2">
            <div></div>
            <div class="d-flex justify-space-between">
              <v-text-field
                v-model="filter.keyword"
                rounded
                filled
                dense
                style="width: 300px"
                append-icon="mdi-magnify"
                single-line
                hide-details
                clearable
                placeholder="Rechercher..."
                v-on:keyup.enter="getItems"
              />

              <v-btn
                rounded
                color="primary"
                class="ml-1"
                elevation="0"
                @click="addCategory"
                >Ajouter</v-btn
              >
            </div>
          </div>

          <v-divider />

          <div v-if="categories.length > 0">
            <v-simple-table elevation="0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Icone</th>
                    <th>Nom</th>
                    <th>Nom Ar</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <draggable tag="tbody"
                v-model="$store.getters.getCategories.data" 
                :sort="true" 
                  @change="orderChanged"
                 
                >
                  <tr v-for="item in categories" :key="item.id">
                    <td>
                      {{ item.id }}
                    </td>
                    <td style="max-width: 50px">
                      <v-img
                        :src="getImageLink(item.image)"
                        max-height="40"
                        contain
                        class="mt-2"
                        v-if="item.image != null"
                      ></v-img>
                    </td>
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      {{ item.name_ar }}
                    </td>
                    <td>
                      <v-menu transition="slide-x-transition" bottom right>
                        <v-list dense>
                          <v-list-item @click="updateCategory(item)">
                            <v-list-item-icon>
                              <v-icon color="black">mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Modifier</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="deleteCategory(item)">
                            <v-list-item-icon>
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Supprimer</v-list-item-title>
                          </v-list-item>
                        </v-list>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="black"
                            icon
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                      </v-menu>
                    </td>
                    <td class="font-weight-bold">
                      <span class="handle font-weight-black">&#8597;</span>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-simple-table>
          </div>
          <div v-else>
            <div class="text-center">
              <v-avatar tile size="200">
                <v-img :src="require('@/assets/database.svg')"></v-img>
              </v-avatar>
              <h3 class="text--primary d-block mb-2">OOPS !</h3>
              <p>Aucun enregistrement correspondant trouvé.</p>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <AddCategoryServiceDialog ref="addCategoryDialog" @refresh="getItems" />
    <UpdateCategoryServiceDialog
      ref="updateCategoryDialog"
      @refresh="getItems"
    />
    <ConfirmDelete ref="confirmDelete" @confirmed="removeCategory" />
  </div>
</template>

<script>
import AddCategoryServiceDialog from "@/views/serviceTypes/dialog/category/AddCategoryServiceDialog";
import UpdateCategoryServiceDialog from "@/views/serviceTypes/dialog/category/UpdateCategoryServiceDialog";
import draggable from "vuedraggable";
import ConfirmDelete from "./dialog/ConfirmDelete.vue";

import { HTTP } from "@/http-common";

export default {
  components: {
    AddCategoryServiceDialog,
    UpdateCategoryServiceDialog,
    ConfirmDelete,
    draggable,
  },
  data() {
    return {
      delete_id: null,
      filter: {},
      orderLoading: false
    };
  },
  methods: {
    getItems() {
      this.$store.dispatch("fetchCategories", {
        filter: {
          size: 10000,
        },
      });
    },
    addCategory() {
      this.$refs.addCategoryDialog.open();
    },
    updateCategory(item) {
      this.$refs.updateCategoryDialog.open(item);
    },
    deleteCategory(item) {
      this.delete_id = item.id;
      this.$refs.confirmDelete.open();
    },
    removeCategory() {
      let id = this.delete_id;

      HTTP.delete("V2/categories/" + id)
        .then(() => {
          this.$successMessage = "Cette Catégorie a été supprimée";
          this.getItems();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    orderChanged() {
      var order = [];
      this.categories.forEach((element) => {
        order.push(element.id);
      });

      HTTP.post("/V2/categories/order/update", {
        order,
      })
        .then(() => {
          this.$successMessage = "Ordre mis a jour";
          this.orderLoading = false;
        })
        .catch((err) => {
          this.orderLoading = false;
          this.uploadErrors = err.response.data.errors;
        });
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.categoriesLoading;
    },
    categories() {
      return this.$store.getters.getCategories.data;
    }
  }
};
</script>