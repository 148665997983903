<template>
    <v-container fluid>
        <div class="main">
            <v-row>
                <v-col cols="3">
                    <v-card class="rounded-lg" elevation="0">

                        <v-card-text>
                            <v-btn block depressed to="/services" color="primary">
                                <v-icon left>mdi-arrow-left</v-icon>
                                Retour
                            </v-btn>
                        </v-card-text>


                        <v-list>
                            <v-list-item-group v-model="model" mandatory>

                                <v-list-item color="primary" class="translateY nav-list" 
                                    active-class="border--primary"
                                    v-for="(item, i) in items" :key="i" :value="item.value">
                                    <v-list-item-icon>
                                        <v-icon color="primary" dense>{{ item.icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title >{{ item.text }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>

                    </v-card>

                </v-col>
                <v-col cols="9">
                    <TypeList v-show="model === 1" ref="typeList" />
                    <SupplierList v-show="model === 2" ref="supplierList" />
                    <CategoriesList v-show="model === 3" ref="categoriesList" />
                    <DefaultServicesList v-show="model === 4" ref="defaultServicesList" />
                    <!-- ecommerceModelesList -->
                    <ListModels v-show="model === 5" ref="ecommerceModelesList"/>
                   
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>

import TypeList from './TypeList.vue';
import SupplierList from '../voucher_supplier/SupplierList.vue';
import CategoriesList from './CategoriesList.vue';
import DefaultServicesList from'./DefaultServicesList.vue';
import ListModels from '../ecommerce/modeles/ListModels.vue';

export default {
    components: {TypeList, SupplierList, CategoriesList, DefaultServicesList, ListModels},
    data() {
        return {
            items: [
                { text: 'Types', icon: 'mdi-clipboard-text-outline', value: 1 },
                { text: 'Suppliers', icon: 'mdi-clipboard-text-outline', value: 2 },
                { text: 'Catégories', icon: 'mdi-clipboard-text-outline', value: 3 },
                { text: 'Services par defaut', icon: 'mdi-clipboard-text-outline', value: 4 },
                { text: 'Modèles Ecommerce', icon: 'mdi-clipboard-text-outline', value: 5 },
            ],
            model: 1,

        }
    },
    methods: {

    },
    computed: {

    },
    mounted() {
        this.$store.dispatch("fetchServiceStates");
       
        this.$refs.typeList.getItems()
        this.$refs.categoriesList.getItems()
    },
    watch: {
        model(val) {
            switch (val) {
                case 1:
                    return this.$refs.typeList.getItems()
                case 2:
                    return this.$refs.supplierList.getItems()
                case 3:
                    return this.$refs.categoriesList.getItems()
                case 4:
                   return this.$refs.defaultServicesList.getItems()
                
                case 5:
                   return this.$refs.ecommerceModelesList.getItems()
            }
        }
    }
}
</script>