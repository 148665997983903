<template>
  <v-container fluid>
    <v-row v-if="loading">
      <v-col cols="6">
        <v-skeleton-loader type="article" />
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader type="article" />
      </v-col>
    </v-row>
    <v-row align-start justify-center v-else>
      <v-col cols="6" class="elevation-0">
        <p class="font-weight-black pink--text darken-2">Services par défaut</p>
        <v-list>
          <draggable
            v-model="defaultServices"
            :options="{ group: 'services' }"
            @change="change"
          >
            <div v-for="item in defaultServices" :key="item.id">
              <v-list-item :key="item.id" class="pointer">
                <v-list-item-avatar class="pointer">
                  <img :src="getImageLink(item.image)" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="pinter">{{
                    item.name
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </draggable>
        </v-list>
      </v-col>
      <v-col cols="6" class="elevation-0">
        <p class="font-weight-black pink--text darken-2">
          Services avec restriction
        </p>
        <v-list rounded>
          <draggable
            v-model="notDefaultServices"
            :options="{ group: 'services' }"
            class="service-item"
          >
            <div v-for="item in notDefaultServices" :key="item.id">
              <v-list-item :key="item.id">
                <v-list-item-avatar class="pointer">
                  <img :src="getImageLink(item.image)" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="pointer">{{
                    item.name
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </draggable>
        </v-list>
      </v-col>
    </v-row>

    <ConfirmAdd ref="confirmAdd" @cancel="cancelAction" />
    <ConfirmRemove ref="confirmDelete" @cancel="cancelAction" />
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { HTTP } from "@/http-common";

import ConfirmAdd from "./dialog/service/ConfirmAddDefault.vue";
import ConfirmRemove from "./dialog/service/ConfirmRemoveDefault.vue";

export default {
  components: { draggable, ConfirmAdd, ConfirmRemove },
  order: 1,
  data() {
    return {
      loading: false,
      defaultServices: [],
      notDefaultServices: [],
      data: [],
    };
  },
  methods: {
    cancelAction() {
        this.createLists();
    },
    fetchData() {
      this.loading = true;
      HTTP.get("/service", {
        params: {
          size: 1000,
        },
      })
        .then((res) => {
          this.data = res.data.data;
          this.createLists();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    createLists(){
        this.defaultServices = this.data.filter(
            (service) => service.is_default_pos
          );

          this.notDefaultServices = this.data.filter(
            (service) => !service.is_default_pos
          );
    },
    getItems() {
      this.fetchData();
    },

    addDefaultService(item) {
      this.$refs.confirmAdd.open(item);
      //   HTTP.post("/service/addDefaultService/" + id)
      //     .then((res) => {
      //       console.log(res);
      //       this.$successMessage = "Mise à jour avec succès";
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
    },

    removeDefaultService(item) {
        this.$refs.confirmDelete.open(item);
    //   HTTP.post("/service/removeDefaultService/" + id)
    //     .then((res) => {
    //       console.log(res);
    //       this.$successMessage = "Mise à jour avec succès";
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    },
    change: function (evt) {
      if (evt.added != null) {
        this.addDefaultService(evt.added.element);
      } else {
        if (evt.removed != null) {
          this.removeDefaultService(evt.removed.element);
        }
      }
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style scoped>
.service_item {
  border-bottom: 1px solid #ddd;
  cursor: pointer !important;
}
.pointer {
  cursor: pointer !important;
}
</style>
