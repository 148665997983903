<template>
    <div>
        <v-card class="rounded-lg shadow" :loading="isLoading" :disabled="isLoading" elevation="0">
            <v-card-text class="p-3">

                <v-skeleton-loader v-if="isLoading" type="table" />

                <div v-else>
                    <div class="d-flex justify-space-between mb-2">
                        <div>

                        </div>
                        <div class="d-flex justify-space-between ">
                            <v-text-field v-model="filter.keyword" rounded filled dense style="width: 300px"
                                append-icon="mdi-magnify" single-line hide-details clearable placeholder="Rechercher..."
                                v-on:keyup.enter="getItems" />

                            <v-btn rounded color="primary" class="ml-1" elevation="0" @click="addType">Ajouter</v-btn>
                        </div>
                    </div>

                    <v-divider />

                    <v-simple-table elevation="0">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Icone</th>
                                    <th>Nom</th>
                                    <th>Nom Ar</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in types" :key="item.id">
                                    <td>
                                        {{ item.id }}
                                    </td>
                                    <td style="max-width: 50px">
                                        <v-img :src="getImageLink(item.icon)" max-height="40" contain class="mt-2"
                                            v-if="item.icon != null"></v-img>
                                    </td>
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td>
                                        {{ item.name_ar }}
                                    </td>
                                    <td>

                                        <v-menu transition="slide-x-transition" bottom right>

                                            <v-list dense>

                                                <v-list-item @click="updateType(item)">
                                                    <v-list-item-icon>
                                                        <v-icon color="black">mdi-pencil</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Modifier</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="deleteType(item)">
                                                    <v-list-item-icon>
                                                        <v-icon color="red">mdi-delete</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Supprimer</v-list-item-title>
                                                </v-list-item>

                                            </v-list>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="black" icon dark v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>


                                        </v-menu>

                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                </div>

            </v-card-text>
        </v-card>
        <AddServiceTypeDialog ref="addTypeServiceDialog" @refresh="getItems" />
        <UpdateServiceTypeDialog ref="updateTypeServiceDialog" @refresh="getItems" />
    </div>
</template>

<script>
import AddServiceTypeDialog from "@/views/serviceTypes/dialog/AddServiceTypeDialog.vue";
import UpdateServiceTypeDialog from "@/views/serviceTypes/dialog/UpdateServiceTypeDialog.vue";

import { HTTP } from "@/http-common";


export default {
    components: { AddServiceTypeDialog, UpdateServiceTypeDialog },
    data() {
        return {
            delete_id: null,
            filter: {}
        }
    },
    methods: {
        getImageLink(file) {
            return process.env.VUE_APP_FILE_URL + file;
        },
        getItems() {
            this.$store.dispatch("fetchServiceTypes");
        },
        addType() {
            this.$refs.addTypeServiceDialog.open();
        },
        updateType(item) {
            this.$refs.updateTypeServiceDialog.open(item);
        },
        deleteType(item) {
            this.delete_id = item.id;
            this.$confirm_dialog = true;

        },
        removeType(){
            HTTP.delete("/service-type/"+this.delete_id)
                .then(() => {
                    this.$successMessage = "Ce Type du Service a été supprimé";
                    this.getItems()
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    },
    computed: {
        isLoading() {
            return this.$store.getters.getLoading;
        },
        types() {
            return this.$store.getters.getServiceTypes;
        }
    },
    watch: {
        $is_confirm: function (el) {
            if (el) {
                this.removeType();
                this.$is_confirm = false;
            }
        },
    },
}
</script>