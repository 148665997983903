<template>
    <div>
      <v-dialog v-model="dialog" max-width="400" persistent>
          <v-card class="text-center">
            <v-card-text class="pa-6">
              <v-icon color="primary" size="80" class="animate__animated animate__rotateIn">mdi-information-outline</v-icon>
               <h2 class="text--primary mt-3">Supprimer un service depuis la liste par defaut</h2>
               <p class="mt-5 mb-5">
                  <v-checkbox v-model="for_all_users" label="Enlever ce service à tous les utilisateurs qui sont déja affectés à ce service"></v-checkbox>
               </p>
              <v-btn text class="rounded-lg mr-2 text-none" large  @click="close()">
                <v-icon left>mdi-close</v-icon>  Annuler
              </v-btn>
              <v-btn  class="rounded-lg text-none" large color="primary" depressed
                     @click="send()">
                <v-icon left>mdi-check-circle-outline</v-icon> Confirmer
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
    </div>
  </template>
  
  <script>
  import { HTTP } from "@/http-common";
  export default {
    data() {
      return {
        dialog: false,
        id_service: null,
        for_all_users: false
      };
    },
    methods: {
      open(service) {
        this.dialog = true;
        this.id_service = service.id;
      },
      close() {
        this.$emit('cancel')
        this.dialog = false;
      },
      send() {
        HTTP.post("/service/removeDefaultService/" + this.id_service,
        {
          for_all_users: (this.for_all_users == true ? 1 : 0)
        })
          .then((res) => {
            console.log(res);
            this.$successMessage = "Mise à jour avec succès";
            this.dialog = false;
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
    computed: {},
    mounted() {},
  };
  </script>