<template>
    <div>
        <v-card class="rounded-lg shadow" :loading="isLoading" :disabled="isLoading" elevation="0">
            <v-card-text class="p-3">

                <v-skeleton-loader v-if="isLoading" type="table" />

                <div v-else>
                    <div class="d-flex justify-space-between mb-2">
                        <div>

                        </div>
                        <div class="d-flex justify-space-between ">
                            <v-text-field v-model="filter.keyword" rounded filled dense style="width: 300px"
                                append-icon="mdi-magnify" single-line hide-details clearable placeholder="Rechercher..."
                                v-on:keyup.enter="getItems" />

                            <v-btn rounded color="primary" class="ml-1" elevation="0" @click="addSupplier">Ajouter</v-btn>
                        </div>
                    </div>

                    <v-divider />

                    <v-simple-table elevation="0">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Image</th>
                                    <th>Nom</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in suppliers" :key="item.id">
                                    <td>
                                        {{ item.id }}
                                    </td>
                                    <td style="max-width: 50px">
                                        <v-img :src="getImageLink(item.image)" max-height="40" contain class="mt-2"
                                            v-if="item.image != null"></v-img>
                                    </td>
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td>

                                        <v-menu transition="slide-x-transition" bottom right>

                                            <v-list dense>

                                                <v-list-item @click="updateSupplier(item)">
                                                    <v-list-item-icon>
                                                        <v-icon color="black">mdi-pencil</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Modifier</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="deleteSupplier(item.id)">
                                                    <v-list-item-icon>
                                                        <v-icon color="red">mdi-delete</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Supprimer</v-list-item-title>
                                                </v-list-item>

                                            </v-list>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="black" icon dark v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>


                                        </v-menu>

                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                </div>

            </v-card-text>
        </v-card>
        <AddSupplierDialog ref="AddSupplierDialog" @refresh="getItems" />
        <UpdateSupplierDialog ref="UpdateSupplierDialog" @refresh="getItems" />
        <ConfirmDelete ref="confirmeDelete" @confirmed="removeSupplier"/>
    </div>
</template>

<script>
import AddSupplierDialog from "@/views/voucher_supplier/dialog/AddSupplierDialog.vue";
import UpdateSupplierDialog from "@/views/voucher_supplier/dialog/UpdateSupplierDialog.vue";


import { HTTP } from "@/http-common";

import ConfirmDelete from "./dialog/ConfirmDelete.vue";
export default {
    components: { AddSupplierDialog, UpdateSupplierDialog, ConfirmDelete },
    data() {
        return {
            supplier_to_delete: -1,
            filter: {}
        }
    },
    methods: {
        getImageLink(file) {
            return process.env.VUE_APP_FILE_URL + file;
        },
        getItems() {
            this.$store.dispatch("fetchSuppliers");
        },
        addSupplier() {
            this.$refs.AddSupplierDialog.open();
        },
        updateSupplier(item) {
            this.$refs.UpdateSupplierDialog.open(item);
        },
        deleteSupplier(id) {
            console.log(id);

            this.supplier_to_delete = id;
            this.$refs.confirmeDelete.open();
            //this.$confirm_dialog = true;

        },
        removeSupplier(){
            if(this.supplier_to_delete){
                console.log(this)
                console.log(this.supplier_to_delete);
            }
            HTTP.delete("/manager/vouchers/suppliers/"+this.supplier_to_delete)
                .then(() => {
                    this.$successMessage = "Ce noeud a été supprimé";
                    this.$store.dispatch("fetchSuppliers");
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    },
    computed: {
        isLoading() {
            return this.$store.getters.getLoading;
        },
        suppliers() {
            return this.$store.getters.getSuppliers;
        }
    },
    watch: {
        $is_confirm: function (el) {
            if (el) {
                this.removeSupplier();
                this.$is_confirm = false;
            }
        },
    },
}
</script>