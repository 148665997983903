<template>
  <div>
    <v-dialog v-model="openDialog" max-width="600" persistent>
      <v-card>
        <v-card-title>
          Modifier un Supplier
          <v-spacer />
          <v-btn icon class="v-btn--active" color="primary" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          <form @submit.prevent="send">
            <v-text-field
              label="Désignation *"
              @input="
                formErrors && formErrors.name ? (formErrors.name = '') : false
              "
              :error-messages="
                formErrors && formErrors.name ? formErrors.name[0] : ''
              "
              v-model="form.name"
            />

            <div class="d-flex align-center">
              <v-file-input
                label="Icone *"
                accept="image/png, image/jpeg, image/jpg, .svg, .gif"
                show-size
                suffix="Image"
                @change="uploadFile"
                @focus="
                  formErrors && formErrors.image
                    ? (formErrors.image = '')
                    : false
                "
                ref="fileImage"
                chips
                prepend-icon="mdi-camera"
                :error-messages="formErrors.image"
              />
              <v-btn
                depressed
                icon
                small
                class="primary white--text ml-2"
                v-if="form.image"
                @click="previewImage(form.image)"
              >
                <v-icon small>mdi mdi-eye</v-icon>
              </v-btn>
            </div>

            <v-btn
              color="primary"
              class="rounded-lg text-none mt-3"
              :loading="btnLoading"
              large
              depressed
              @click="send"
            >
              <v-icon left>mdi-send-outline</v-icon>
              Savegarder
            </v-btn>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      btnLoading: false,

      formErrors: {},
      form: {
        name: "",
        image: "",
      },
      openDialog: false,
      language_tab: null,
      supplier_id: null,
    };
  },

  computed: {},
  methods: {
    open(item) {
      this.form.name = item.name;
      this.supplier_id = item.id;
      this.openDialog = true;
    },
    close() {
      //this.$emit("update:openDialog", false);
      this.openDialog = false;
    },
    send() {
      this.btnLoading = true;

      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });

      HTTP.post("/manager/vouchers/suppliers/" + this.supplier_id, formData, {
        params: {},
      })
        .then(() => {
          this.$successMessage = "Ce Supplier a été crée avec succès";
          this.btnLoading = false;
          this.close();
          this.form = {};
          this.$refs.fileImage.reset();

          this.openDialog = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          this.btnLoading = false;
          this.formErrors = err.response.data.errors;
        });
    },

    uploadFile(file) {
      this.form.image = file;
      console.log("icon changed");
    },
  },
  created() {},
};
</script>
  
  